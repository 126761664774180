<script setup lang="ts">
const props = defineProps<{ currentPrice: number; priceLimit: number }>();
const percent = computed(() => (props.currentPrice / props.priceLimit) * 100);
const amountLeft = computed(() => props.priceLimit - props.currentPrice);
</script>
<template>
  <div
    class="bg-light-grey flex flex-col items-center gap-3 px-3 py-3 text-sm font-bold"
  >
    <div class="flex w-full items-center gap-3 whitespace-nowrap">
      <p>€ 0,00</p>
      <span class="bg-grey h-[3px] w-full">
        <span
          class="bg-dark block h-[3px]"
          :style="{ width: (percent > 100 ? 100 : percent) + '%' }"
        />
      </span>
      <p>€ {{ priceLimit }},00</p>
    </div>
    <p
      v-if="currentPrice < priceLimit"
      class="text-grey text-center text-sm font-thin"
    >
      Nur noch
      <span class="text-dark font-bold">
        € {{ amountLeft.toLocaleString('de-DE') }}
      </span>
      bis zum
      <span class="text-dark font-bold">kostenlosen Versand.</span>
    </p>
    <p v-else>Kostenloser Versand</p>
  </div>
</template>
